import reports from '@/navigation/vertical/reports'
import spamManagement from '@/navigation/vertical/spam-management'
import salesforceInformation from '@/navigation/vertical/salesforce-information'
import performanceInsight from '@/navigation/vertical/performance-insight'
import domains from '@/navigation/vertical/domains'
import productOffering from '@/navigation/vertical/heroai/product-offering'
import settings from '@/navigation/vertical/heroai/settings'
import masters from '@/navigation/vertical/heroai/masters'

export default [
  {
    icon: 'BriefcaseIcon',
    title: 'Campaigns',
    route: 'campaigns-list',
    resource: 'Campaigns',
    action: 'Access',
  },
  ...salesforceInformation,
  {
    icon: 'CreditCardIcon',
    title: 'Wallets',
    route: 'wallets-list',
    resource: 'Wallets',
    action: 'Access',
  },
  ...performanceInsight,
  ...spamManagement,
  ...reports,
  {
    icon: 'TerminalIcon',
    title: 'Prompt Library',
    route: 'prompt-library',
    resource: 'Prompts',
    action: 'Access',
  },
  ...domains,
  ...productOffering,
  ...settings,
  ...masters,
]
