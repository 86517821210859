export default [
  {
    icon: 'FileTextIcon',
    title: 'Lead Management',
    children: [
      {
        icon: 'FileTextIcon',
        title: 'Spam Management',
        route: 'spam-management-leads',
        resource: 'Spam Management',
        action: 'Access',
      },
      {
        icon: 'FileTextIcon',
        title: 'Blacklist Leads',
        route: 'blacklist-leads',
        resource: 'Blacklist Leads',
        action: 'Access',
      },
      {
        icon: 'FileTextIcon',
        title: 'Unmapped Leads',
        route: 'unmapped-leads',
      },
      {
        icon: 'FacebookIcon',
        title: 'Lead Generation (TH)',
        route: 'lead-generation-th',
        resource: 'Lead Generation (TH)',
        action: 'Access',
      },
      {
        icon: 'GlobeIcon',
        title: 'TikTok Lead Generation',
        route: 'tiktok-lead-generation-index',
        resource: 'TikTok Lead Generation',
        action: 'Access',
      },
      {
        icon: 'ToolIcon',
        title: 'Lead Service Support',
        route: 'lead-service-support',
        resource: 'Lead Service Support',
        action: 'Access',
      },
      {
        icon: 'ToolIcon',
        title: 'Ads Campaign Syncing',
        route: 'ads-campaign-syncing',
        resource: 'Ads Campaign Syncing',
        action: 'Access',
      },
    ],
  },
]
